import { createInertiaApp } from "@inertiajs/svelte";

// Only start the Inertia app if there's an #app element, otherwise this will throw an error
if (document.getElementById("app")) {
  createInertiaApp({
    // Set default page title
    // see https://inertia-rails.netlify.app/guide/title-and-meta
    //
    // title: title => title ? `${title} - App` : 'App',

    // Disable progress bar
    //
    // see https://inertia-rails.netlify.app/guide/progress-indicators
    // progress: false,

    resolve: (name) => {
      const pages = import.meta.glob("../pages/**/*.svelte", { eager: true });
      return pages[`../pages/${name}.svelte`];

      // To use a default layout, import the Layout component
      // and use the following lines.
      // see https://inertia-rails.netlify.app/guide/pages#default-layouts
      //
      // const page = pages[`../pages/${name}.vue`]
      // page.default.layout ||= (page) => createElement(Layout, null, page)
      // return page
    },

    setup({ el, App, props }) {
      new App({ target: el, props });
    },
  });
}
